.popover-container {
  max-width: 230px;
  /* min-height: 50px; */
  border-radius: 15px;
  padding: 15px;
  /* min-width: 130px; */
}

.popover-arrow,
.popover-arrow::before {
  position: absolute;
  width: 13px;
  height: 13px;
  background: inherit;
}

.popover-arrow {
  visibility: hidden;
}

.popover-arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
  /* border-left: 2px solid #c7a472;
  border-top: 2px solid #c7a472; */
  border-left: 1px solid #bc6c1e;
  border-top: 1px solid #bc6c1e;
}

.popover-arrow-no-border::before {
  border-left: none;
  border-top: none;
}

.popover-container[data-popper-placement^="top"] > .popover-arrow {
  bottom: -7px;
}

.popover-container[data-popper-placement^="top"] > .popover-arrow::before {
  transform: rotate(-135deg);
}

.popover-container[data-popper-placement^="bottom"] > .popover-arrow {
  /* top: -9px; */
  top: -8px;
}

.popover-container[data-popper-placement^="left"] > .popover-arrow {
  right: -7px;
}

.popover-container[data-popper-placement^="left"] > .popover-arrow::before {
  transform: rotate(135deg);
}

.popover-container[data-popper-placement^="right"] > .popover-arrow {
  left: -8px;
}

.popover-container[data-popper-placement^="right"] > .popover-arrow::before {
  transform: rotate(-45deg);
}
