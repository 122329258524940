@font-face {
  font-family: "FontAwesome";
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.woff")
      format("woff"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.ttf")
      format("truetype"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.svg?#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
.rc-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  /* padding-right: 0; */
  list-style: none;
  /* border: 1px solid #d9d9d9; */
  border-radius: 3px;
  color: #905419;
  margin-block-start: 0;
}
.rc-menu-rtl {
  direction: rtl;
}
.rc-menu-hidden {
  display: none;
}
.rc-menu-collapse {
  overflow: hidden;
  transition: height 0.3s ease-out;
}
.rc-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.rc-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
}
.rc-menu-item:hover > a,
.rc-menu-submenu-title:hover > span {
  transform: translateX(5px);
}
.rc-menu-item-selected {
  transform: translateZ(0);
}

.rc-menu-item-selected,
.rc-menu-submenu-selected > .rc-menu-submenu-title {
  background-image: url(../../../../images/sidebar/sidebar-button-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
}
.rc-menu-sub > .rc-menu-item-selected {
  background-image: none;
}

.rc-menu-item-selected .menu-icon-img .cls-1,
.rc-menu-submenu-selected .menu-icon-img .cls-1 {
  fill: #fff;
}

.rc-menu > li.rc-menu-submenu {
  padding: 0;
}
.rc-menu-horizontal.rc-menu-sub,
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  min-width: 160px;
  margin-top: 0;
}
.rc-menu-item,
.rc-menu-submenu-title {
  margin: 0;
  position: relative;
  display: block;
  padding: 7px 7px 7px 16px;
  white-space: nowrap;
  cursor: pointer;
}
.rc-menu-root > .rc-menu-item {
  margin-top: 5px;
}
.rc-menu-rtl .rc-menu-item,
.rc-menu-rtl .rc-menu-submenu-title {
  padding: 7px 16px 7px 7px;
}
.rc-menu-item.rc-menu-item-disabled,
.rc-menu-submenu-title.rc-menu-item-disabled,
.rc-menu-item.rc-menu-submenu-disabled,
.rc-menu-submenu-title.rc-menu-submenu-disabled {
  color: #777 !important;
}
.rc-menu > .rc-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: #e5e5e5;
}
.rc-menu-submenu-popup {
  position: absolute;
}
.rc-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.rc-menu-submenu-rtl.rc-menu-submenu-popup .submenu-title-wrapper,
.rc-menu-submenu-rtl .rc-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 0;
  padding-left: 20px;
}
/* .rc-menu-submenu > .rc-menu {
  background-color: #fff;
} */
.rc-menu .rc-menu-submenu-title .anticon,
.rc-menu .rc-menu-item .anticon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  top: -1px;
}
.rc-menu-rtl .rc-menu .rc-menu-submenu-title .anticon,
.rc-menu-rtl .rc-menu .rc-menu-item .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.rc-menu-horizontal {
  background-color: #f3f5f7;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
}
.rc-menu-horizontal > .rc-menu-item,
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 15px 20px;
}
.rc-menu-horizontal > .rc-menu-submenu,
.rc-menu-horizontal > .rc-menu-item {
  border-bottom: 2px solid transparent;
  display: inline-block;
  vertical-align: bottom;
}
.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  border-bottom: 2px solid #2db7f5;
  background-color: #f3f5f7;
  color: #2baee9;
}
.rc-menu-horizontal:after {
  content: "\20";
  display: block;
  height: 0;
  clear: both;
}

.rc-menu-vertical > .rc-menu-item,
.rc-menu-vertical-left > .rc-menu-item,
.rc-menu-vertical-right > .rc-menu-item,
.rc-menu-inline > .rc-menu-item,
.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 8px 8px 12px 16px;
}
.rc-menu-rtl.rc-menu-vertical > .rc-menu-item,
.rc-menu-rtl.rc-menu-vertical-left > .rc-menu-item,
.rc-menu-rtl.rc-menu-vertical-right > .rc-menu-item,
.rc-menu-rtl.rc-menu-inline > .rc-menu-item,
.rc-menu-rtl.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-rtl.rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-rtl.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-rtl.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 12px 24px 12px 8px;
}
.rc-menu-vertical .rc-menu-submenu-arrow,
.rc-menu-vertical-left .rc-menu-submenu-arrow,
.rc-menu-vertical-right .rc-menu-submenu-arrow,
.rc-menu-inline .rc-menu-submenu-arrow {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  top: 12px;
  right: 16px;
  line-height: 1.5em;
}
.rc-menu-vertical .rc-menu-submenu-arrow:before,
.rc-menu-vertical-left .rc-menu-submenu-arrow:before,
.rc-menu-vertical-right .rc-menu-submenu-arrow:before,
.rc-menu-inline .rc-menu-submenu-arrow:before {
  content: "\f0da";
}
.rc-menu-rtl.rc-menu-vertical .rc-menu-submenu-arrow:before,
.rc-menu-rtl.rc-menu-vertical-left .rc-menu-submenu-arrow:before,
.rc-menu-rtl.rc-menu-vertical-right .rc-menu-submenu-arrow:before,
.rc-menu-rtl.rc-menu-inline .rc-menu-submenu-arrow:before,
.rc-menu-submenu-rtl .rc-menu-vertical .rc-menu-submenu-arrow:before,
.rc-menu-submenu-rtl .rc-menu-vertical-left .rc-menu-submenu-arrow:before,
.rc-menu-submenu-rtl .rc-menu-vertical-right .rc-menu-submenu-arrow:before,
.rc-menu-submenu-rtl .rc-menu-inline .rc-menu-submenu-arrow:before {
  content: "\f0d9";
}
.rc-menu-rtl.rc-menu-vertical .rc-menu-submenu-arrow,
.rc-menu-rtl.rc-menu-vertical-left .rc-menu-submenu-arrow,
.rc-menu-rtl.rc-menu-vertical-right .rc-menu-submenu-arrow,
.rc-menu-rtl.rc-menu-inline .rc-menu-submenu-arrow,
.rc-menu-submenu-rtl .rc-menu-vertical .rc-menu-submenu-arrow,
.rc-menu-submenu-rtl .rc-menu-vertical-left .rc-menu-submenu-arrow,
.rc-menu-submenu-rtl .rc-menu-vertical-right .rc-menu-submenu-arrow,
.rc-menu-submenu-rtl .rc-menu-inline .rc-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.rc-menu-inline .rc-menu-submenu-arrow {
  transform: rotate(90deg);
  transition: transform 0.3s;
}
.rc-menu-inline
  .rc-menu-submenu-open
  > .rc-menu-submenu-title
  .rc-menu-submenu-arrow {
  transform: rotate(-90deg);
}
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  padding: 0;
}
.rc-menu-submenu-rtl .rc-menu-vertical.rc-menu-sub,
.rc-menu-submenu-rtl .rc-menu-vertical-left.rc-menu-sub,
.rc-menu-submenu-rtl .rc-menu-vertical-right.rc-menu-sub {
  direction: rtl;
}
.rc-menu-submenu-selected .rc-menu-sub.rc-menu-inline {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: #fabc32;
}
.rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 0;
}
.rc-menu-rtl .rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-rtl
  .rc-menu-sub.rc-menu-inline
  > .rc-menu-submenu
  > .rc-menu-submenu-title {
  padding-left: 0;
}
.rc-menu-open-slide-up-enter,
.rc-menu-open-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
.rc-menu-open-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
.rc-menu-open-slide-up-enter.rc-menu-open-slide-up-enter-active,
.rc-menu-open-slide-up-appear.rc-menu-open-slide-up-appear-active {
  animation-name: rcMenuOpenSlideUpIn;
  animation-play-state: running;
}
.rc-menu-open-slide-up-leave.rc-menu-open-slide-up-leave-active {
  animation-name: rcMenuOpenSlideUpOut;
  animation-play-state: running;
}
@keyframes rcMenuOpenSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcMenuOpenSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
.rc-menu-open-zoom-enter,
.rc-menu-open-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
.rc-menu-open-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
.rc-menu-open-zoom-enter.rc-menu-open-zoom-enter-active,
.rc-menu-open-zoom-appear.rc-menu-open-zoom-appear-active {
  animation-name: rcMenuOpenZoomIn;
  animation-play-state: running;
}
.rc-menu-open-zoom-leave.rc-menu-open-zoom-leave-active {
  animation-name: rcMenuOpenZoomOut;
  animation-play-state: running;
}
.rc-menu-submenu-rtl.rc-menu-open-zoom-enter,
.rc-menu-submenu-rtl.rc-menu-open-zoom-appear,
.rc-menu-submenu-rtl.rc-menu-open-zoom-leave,
.rc-menu-submenu-rtl .rc-menu-open-zoom-enter,
.rc-menu-submenu-rtl .rc-menu-open-zoom-appear,
.rc-menu-submenu-rtl .rc-menu-open-zoom-leave {
  transform-origin: top right !important;
}
@keyframes rcMenuOpenZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes rcMenuOpenZoomOut {
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

.rc-menu-root {
  /* padding-top: calc(4rem + 4%); */
}

.rc-menu-item > a {
  color: #905419;
  display: block;
}
.rc-menu-item-selected > a {
  color: white;
}
.submenu-title-wrapper {
  position: relative;
  display: block;
}
.rc-menu-item > a,
.submenu-title-wrapper {
  overflow: hidden;
  transition: transform 0.25s ease;
}

.rc-menu-item > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rc-menu-item-selected > a > .menu-cirlce {
  border-color: white;
  filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 0.33));
}

.rc-menu-submenu > .rc-menu-inline .rc-menu-item {
  padding-left: 24px !important;
}

.menu-icon-img-box {
  display: inline-block;
  width: 21px;
  height: 21px;
}

.menu-icon-img {
  position: absolute;
  top: 4px;
}

.rc-menu-item-selected .menu-icon-img,
.rc-menu-submenu-selected .menu-icon-img {
  left: 0;
  filter: drop-shadow(0 3px 0 #33333333);
}

.rc-menu-submenu-selected .submenu-title-wrapper,
.rc-menu-item-selected .active {
  text-shadow: 0 3px rgba(0, 0, 0, 0.33);
  font-weight: 500;
}

.rc-menu-submenu-selected .rc-menu-submenu-arrow {
  filter: drop-shadow(3px 0 0 rgba(0, 0, 0, 0.33));
}

.rc-menu-submenu-selected.rc-menu-submenu-open .rc-menu-submenu-arrow {
  filter: drop-shadow(-3px 0 0 rgba(0, 0, 0, 0.33));
}

.rc-menu-sub.rc-menu-inline > .rc-menu-item {
  font-size: 1rem;
}

.rc-menu-submenu-inline > .rc-menu-submenu-title,
.rc-menu-inline > .rc-menu-item {
  padding-left: 16px !important;
}

@media (max-width: 1480px) {
  .rc-menu-vertical > .rc-menu-item,
  .rc-menu-vertical-left > .rc-menu-item,
  .rc-menu-vertical-right > .rc-menu-item,
  .rc-menu-inline > .rc-menu-item,
  .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 8px 8px 8px 14px;
  }
}

@media (max-width: 1280px) {
  .menu-icon-img-box {
    height: 16px;
    width: 16px;
  }

  .menu-icon-img {
    position: absolute;
    top: 3px;
  }

  .rc-menu-submenu-inline > .rc-menu-submenu-title,
  .rc-menu-inline > .rc-menu-item {
    padding-left: 14px !important;
  }

  .rc-menu-item:hover > a,
  .rc-menu-submenu-title:hover > span {
    transform: translateX(0px);
  }

  .rc-menu-submenu > .rc-menu-inline .rc-menu-item {
    padding-left: 19px !important;
  }

  .rc-menu-sub.rc-menu-inline > .rc-menu-item {
    font-size: 0.9rem;
  }
}
