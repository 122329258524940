body {
  margin: 0;
  font-family: Kanit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffaf2;
  color: #514b45;
  font-weight: 400;
  background-image: url(images/vein.png);
  -webkit-tap-highlight-color: transparent; /* 移动端点击背景色 */
  -webkit-appearance: none;
}

html {
  font-size: 16px;
  /* overflow: scroll; */
}

@media (max-width: 1480px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 500px) {
  html,
  body {
    overflow: hidden;
    height: 100%;
  }

  body {
    background-size: 12%;
  }

  .mobile-hidden {
    display: none !important;
  }
}

#root {
  height: 100%;
}

a,
a:active {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.width100 {
}

.bg-di_2 {
  background-image: url(../src/images/di_2.png) !important;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.display-inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}
.flex-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.align-start {
  align-items: flex-start;
}

.grow-1 {
  flex-grow: 1;
}

.width20 {
  width: 20%;
}

.width33 {
  width: 33%;
}

.width50 {
  width: 50%;
}

.margin-auto {
  margin: 0 auto;
}

button,
.button {
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
}
button:disabled {
  cursor: default;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute-center {
  left: 50%;
  transform: translateX(-50%);
}

.middle {
  vertical-align: middle;
}
.mt-2 {
  margin-top: 2px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mtn-10 {
  margin-top: -10px;
}

.mr-1 {
  margin-right: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-7 {
  margin-right: 7px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}

.ml-3 {
  margin-left: 3px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-6 {
  margin-left: 6px;
}
.mln-5 {
  margin-left: -5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-50 {
  margin-left: 50px;
}

.mb-2 {
  margin-bottom: 2px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mbn-40 {
  margin-bottom: -40px;
}

.padding-5 {
  padding: 5px;
}
.pt-1 {
  padding-top: 1px;
}
.pt-2 {
  padding-top: 2px;
}
.pt-3 {
  padding-top: 3px;
}
.pb-1 {
  padding-bottom: 1px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}

.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-20 {
  padding-right: 20px;
}
.pl-4 {
  padding-left: 4px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-20 {
  padding-left: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-32 {
  font-size: 32px;
}

.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}

.rfs-8 {
  font-size: 0.8rem;
}
.rfs-9 {
  font-size: 0.9rem;
}
.rfs-10 {
  font-size: 1rem;
}
.rfs-11 {
  font-size: 1.1rem;
}
.rfs-125 {
  font-size: 1.25rem;
}
.rfs-12 {
  font-size: 1.2rem;
}
.rfs-13 {
  font-size: 1.3rem;
}
.rfs-14 {
  font-size: 1.4rem;
}
.rfs-15 {
  font-size: 1.5rem;
}
.rfs-16 {
  font-size: 1.6rem;
}
.rfs-17 {
  font-size: 1.7rem;
}
.rfs-18 {
  font-size: 1.8rem;
}
.rfs-19 {
  font-size: 1.9rem;
}
.rfs-20 {
  font-size: 2rem;
}
.rfs-22 {
  font-size: 2.2rem;
}
.rfs-24 {
  font-size: 2.4rem;
}

/* 不换行 */
.no-wrap {
  white-space: nowrap;
}

/* 颜色 */
.color-price {
  color: #fdd456;
}

.color-owner {
  color: #fd7409;
}

.notice-text-color {
  color: #dd0022;
}

.white {
  color: white;
}

.color-normal {
  color: #514b45;
}

/* 文字描边 */
.text-stroke-1 {
  -webkit-text-stroke: 1px black;
}

/* 字体 */
.ff-game {
  font-weight: 600;
}

.ff-black {
  font-family: AvenirNextLTPro;
}

::-webkit-scrollbar {
  display: none;
}

.react-tabs__tab-list {
  line-height: normal !important;
  height: auto !important;
  border-radius: 4.4rem !important;
  background-color: #fff2d0 !important;
}

.react-tabs__tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  list-style: none;
  margin: 0 2px;
  padding: 4px 15px !important;
  cursor: pointer;
  background-color: transparent !important;
  border-radius: 4.4rem !important;
  font-size: 1.3rem;
  font-weight: 600;
  color: #bc7a4a !important;
  opacity: 1;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .react-tabs__tab {
    padding: 4px 10px !important;
  }
}

.react-tabs__tab:focus {
  background-color: #fdce4f !important;
  border-bottom-color: #fdce4f !important;
  color: #514b45 !important;
  box-shadow: 0 2px 0 #bc7a4a !important;
}

@media (any-hover: hover) {
  .react-tabs__tab:hover {
    opacity: 0.65;
  }
}

.react-tabs__tab:active {
  color: #815332 !important;
  opacity: 0.85;
  transform: translateY(1px);
}

.react-tabs__tab--selected {
  background-color: #fdce4f !important;
  border: none !important;
  border-bottom: 1px #fdce4f solid !important;
  color: #514b45 !important;
  box-shadow: 0 2px 0 #bc7a4a;
}

.react-tabs__tab--selected:active {
  background-color: #ddb344 !important;
}

/* box阴影 */
.shadow {
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
}

.transition-group {
  position: relative;
  height: 100%;
}

.page-container {
  position: relative;
  /* overflow-y: auto;
  overflow-x: hidden; */
  height: 100%;
}

@media (max-width: 500px) {
  .page-container {
    height: calc(100% - 72px);
    padding-bottom: 4.5rem;
  }
}

.fade-enter {
  opacity: 0;
  transform: scale(0.97);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 280ms, transform 300ms ease-in;
}

.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: scale(1.03);
  transition: opacity 280ms, transform 350ms ease-out;
}

.guide-enter {
  opacity: 0;
  /* transform: scale(0.9); */
}

.guide-enter.guide-enter-active {
  opacity: 1;
  /* transform: scale(1); */
  transition: opacity 1500ms;
}

.guide-exit {
  opacity: 0;
  /* transform: scale(1); */
}

.guide-exit.guide-exit-active {
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 1500ms;
}

/* 人物浮动动画 */
@keyframes slideup {
  from {
    transform: translateY(5px);
  }

  to {
    transform: translateY(0);
  }
}

/* 英雄卡牌浮动动画 */
@keyframes hero-card-up {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0);
  }
}

/* 英雄卡牌阴影动画 */
@keyframes hero-card-shadow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotateAnti {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes magnify {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(3);
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0.8;
  }

  50% {
    transform: translateY(5px);
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}

@keyframes lePeek {
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
}

@keyframes wordsLoopInitial {
  0% {
    transform: translateX(200px);
    -webkit-transform: translateX(200px);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes wordsLoop2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes rowLoop {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(calc(300px - 100%));
  }
}

@keyframes circleRotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    opacity: 0.7;
  }
  24.5% {
    opacity: 0;
    transform: rotate(88.2deg) scale(1);
  }
  25.5% {
    opacity: 0;
    transform: rotate(92.4deg) scale(0.6);
  }
  50% {
    opacity: 0.7;
    transform: rotate(180deg) scale(0.8);
  }
  74.5% {
    opacity: 0;
    transform: rotate(268.2deg) scale(1);
  }
  75.5% {
    opacity: 0;
    transform: rotate(272.4deg) scale(0.6);
  }
  100% {
    opacity: 0.7;
    transform: rotate(360deg) scale(0.8);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.9;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

@keyframes eggRotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  10% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  20% {
    transform: translate(-50%, -50%) rotate(-5deg);
  }
  30% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  40% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  60% {
    transform: translate(-50%, -50%) rotate(-5deg);
  }
  70% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  90% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes eggRotate2 {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes elementShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
