.card_sign_disabled {
  position: relative;
  pointer-events: none;
  /* border: 0 !important; */
}
.card_sign_disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  z-index: 2;
}

.border-sign {
  animation: glow 1600ms ease-out infinite alternate;
}
@keyframes glow {
  0% {
    border-color: #fdcd4f;
  }
  50% {
    border-color: #fdcd4f;
  }
  100% {
    border-color: #ffffff00;
  }
}
