input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.page_disabled {
  position: relative;
  pointer-events: none;
}

.card_disabled {
  position: relative;
  pointer-events: none;
}
.card_disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 66, 66, 0.5);
  z-index: 2;
}

.pets_card_disabled {
  position: relative;
  pointer-events: none;
}

.head-card-disabled {
  position: relative;
  pointer-events: none;
}

.head-card-disabled::after {
  z-index: 9 !important;
  background-color: rgba(66, 66, 66, 0.5);
}

.head-card-disabled-busy {
  position: relative;
  pointer-events: none;
}

/* .head-card-disabled-busy:first-child::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 66, 66, 0.5);
  z-index: 9;
  border-radius: 9px;
} */

@media (min-width: 500px) {
  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
  }
}

/* @media (min-width: 500px) {
  .react-tabs {
    min-width: 500px;
  }
} */

.react-tabs__tab-list {
  display: inline-flex;
  list-style-type: none;
  /* margin-top: 0; */
  padding: 2px;
  color: white;
  background-color: #514b45;
  border-radius: 5px;
  height: 24px;
  line-height: 22px;
  /* box-sizing: border-box; */
}

.react-tabs__tab {
  padding: 2px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  padding: 1px;
  background-color: #6a9cd9;
  border: 1px solid #a8cdfa;
  border-radius: 5px;
  outline: 0 none;
}

.select-grid {
  left: 50%;
  transform: translateX(-50%);
}

.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
}

.tooltip-container {
  z-index: 9;
}

.shop-transition {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.85, 0.36);
  transform-origin: left top;
}

@media (max-width: 500px) {
  .mobileZoom90 {
    zoom: 0.9;
  }
}

.text-shadow {
  text-shadow: #333 1px 0 0, #333 0 1px 0, #333 -1px 0 0, #333 0 -1px 0;
}

.ant-radio-inner {
  background-color: #8e6800 !important;
  border-color: #8e6800 !important;
}

.ant-radio-inner::after {
  background-color: #fdd770 !important;
  width: 26px !important;
  height: 26px !important;
  margin-block-start: -13px !important;
  margin-inline-start: -13px !important;
}

.ant-radio-wrapper span:last-child {
  padding-left: 4px;
  font-family: Kanit, sans-serif;
  font-weight: bold;
  color: #514b45;
}

.ant-popover-inner {
  /* border: 1px solid rgb(188, 108, 30); */
  box-shadow: rgb(0 0 0 / 30%) 0px 5px 8px 2px !important;
  font-size: 1rem;
  color: rgb(81, 75, 69);
  font-weight: 400;
}
